import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import { Instagram, Facebook } from "react-feather"

import Head from "../components/Head"
import Template from "../components/Template"

import snapchatIcon from "../icons/snapchat.svg"

export default () => {
  const data = require("../cms/contact.json")
  const email = data.email

  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("")
  const [mailTo, setMailTo] = useState(`mailto:${email}`)

  useEffect(() => {
    setMailTo(`mailto:${email}?subject=${subject}&body=${body}`)
  }, [subject, body, email])

  return (
    <>
      <Head title="Contact" />

      <Template
        h1="Contact"
      >
        <div
          css={css`
            max-width: max-content;
            margin: 0 auto;
            margin-bottom: 48px;
            padding: 16px 32px;

            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-column-gap: 24px;

            :hover > a{
              opacity: .3;
            }

            > a {
              transition: opacity .2s;
            }

            > a:hover {
              opacity: 1;
            }
          `}
        >

          <a href={`https://instagr.am/${data.instagram}`} target="_blank" rel="noopener noreferrer">
            <Instagram size={64} strokeWidth={1.5} />
          </a>

          <a href={`https://snapchat.com/add/${data.snapchat}`} target="_blank" rel="noopener noreferrer">
            <img
              src={snapchatIcon}
              css={css`
                height: 64px;
                width: 64px;
                user-select: none;
              `}
              alt="Snapchat logo"
            />
          </a>

          <a href={data.facebook} target="_blank" rel="noopener noreferrer">
            <Facebook size={64} strokeWidth={1.5} />
          </a>

        </div>

        <form
          css={css`
            margin: 0 auto;
            max-width: 40rem;

            input, textarea {
              background: transparent;
              border: none;
              outline: none;
              width: 100%;

              border: 1.5px solid var(--text-lightgray);
              transition: border-color .2s;
              border-radius: 5px;
              margin-bottom: 1rem;
              padding: .6rem;
            }

            textarea {
              resize: vertical;
              min-height: 15rem;
            }

            label {
              display: block;
              color: var(--text-gray);
              margin-bottom: .5rem;
            }
          `}
        >
          <label htmlFor="subject">Subject</label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={event => setSubject(event.target.value)}
          />

          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={body}
            onChange={event => setBody(event.target.value)}
          />

          <a
            css={css`
              background-color: var(--text-gray);
              color: white;
              font-weight: 500;
              text-decoration: none;
              display: block;
              padding: .6rem 1.2rem;
              border-radius: 5px;
              max-width: max-content;
              margin-right: 0;
              margin-left: auto;
              margin-top: 1rem;
              user-select: none;
              transition: opacity .2s;

              @media (hover: hover) {
                :hover {
                  opacity: .9;
                }
              }
            `}
            href={mailTo}
          >
            Send
          </a>
        </form>
      </Template>
    </>
  )
}